import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./PersonalTab.css";
import { Carousel } from "react-responsive-carousel";
import ContactSales from "./ContactSales";
import { Image } from "@fluentui/react-components";

export default function PersonalTab() {
  return (
    <div className="PersonalTab">
      <Carousel
        showArrows={false}
        showStatus={false}
        autoPlay={false}
        infiniteLoop={true}
      >
        <div>
          <ContactSales />
        </div>
        <div>
          <div className="Tutorial">
            <h1>Welcome to the Byrdhouse Microsoft Teams Tutorial 🌎</h1>
            <h1 style={{ color: "#e76399" }}>Step 1</h1>
            <h1 style={{ color: "#e76399" }}>
              Add Byrdhouse AI to your Microsoft Teams meeting
            </h1>
            <div style={{ marginTop: "20px" }}>
              <Image style={{ maxWidth: "30%" }} src={"tutorial-1.png"} />
              <Image style={{ maxWidth: "30%" }} src={"tutorial-2.png"} />
            </div>
          </div>
        </div>
        <div>
          <div className="Tutorial">
            <h1>Welcome to the Byrdhouse Microsoft Teams Tutorial 🌎</h1>
            <h1 style={{ color: "#ffab00" }}>Step 2</h1>
            <h1 style={{ color: "#ffab00" }}>
              Add Languages and Configure Settings
            </h1>
            <div style={{ marginTop: "20px" }}>
              <Image
                style={{
                  maxWidth: "60%",
                  boxShadow:
                    "0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19)",
                }}
                src={"tutorial-3.png"}
              />
            </div>
          </div>
        </div>
        <div>
          <div className="Tutorial">
            <h1>Welcome to the Byrdhouse Microsoft Teams Tutorial 🌎</h1>
            <h1 style={{ color: "#36B37E" }}>Step 3</h1>
            <h1 style={{ color: "#36B37E" }}>
              Enjoy the real-time translation experience in Microsoft Teams
              meetings
            </h1>
            <div style={{ marginTop: "20px" }}>
              <Image style={{ maxWidth: "60%" }} src={"tutorial-4.png"} />
            </div>
          </div>
        </div>
      </Carousel>
    </div>
  );
}
