import { useEffect, useMemo, useRef } from "react";
import "./Captions.css";
import { useDataChannel } from "@livekit/components-react";
import useUser from "../hooks/user";
import { Caption, getTextLanguage } from "../types";
import useLayout, { useToggleDialog } from "../hooks/layout";
import { useGetLocalizedText } from "../hooks/localization";
import useCaptions from "../hooks/captions";
import { IoFlag } from "react-icons/io5";

const decoder = new TextDecoder();

type CaptionsProps = {
  hide?: boolean;
};

function Captions(props: CaptionsProps) {
  const getLocalizedText = useGetLocalizedText();

  const captionsRef = useRef<HTMLDivElement | null>(null);

  const lastCaptionAdded = useRef(Date.now());

  const [captions, setCaptions] = useCaptions();

  useDataChannel((message) => {
    const caption = JSON.parse(decoder.decode(message.payload)) as Caption;
    if (
      Math.abs(Date.now() - lastCaptionAdded.current) <
        layout.settings.textSpeed * 1000 &&
      !caption.complete
    ) {
      return;
    }
    lastCaptionAdded.current = Date.now();
    setCaptions((prevState) => ({
      ...prevState,
      [caption.id]: caption,
    }));
  });

  useEffect(() => {
    // Auto-scroll to bottom
    captionsRef.current?.scrollTo({
      top: captionsRef.current.scrollHeight,
    });
  }, [captions]);

  const [user] = useUser();

  const selectedLanguage = useMemo(() => {
    return getTextLanguage(user.metadata.selectedLanguage) || "en";
  }, [user.metadata.selectedLanguage]);

  const [layout] = useLayout();

  const toggleReportTranslationDialog = useToggleDialog("report-translation");

  if (props.hide) {
    return (
      <div className="Captions" ref={captionsRef}>
        <h4>🗣️ {getLocalizedText("VOICE_TO_VOICE")}</h4>
      </div>
    );
  }

  return (
    <div className="Captions" ref={captionsRef}>
      {Object.values(captions)
        .filter((c) => c.translations[selectedLanguage]?.length)
        .map((caption) => (
          <div>
            <div
              style={{
                color:
                  caption.identity === user.livekitId ? "#101010" : "white",
                backgroundColor:
                  caption.identity === user.livekitId ? "#ccc" : "#00814A",
                fontSize: `${layout.settings.textSize}px`,
                borderRadius: "5px",
                padding: "5px",
              }}
              key={caption.id}
            >
              {caption.name.split(" ")[0] || caption.name}:{" "}
              {caption.translations[selectedLanguage]}
            </div>
            <IoFlag
              onClick={() =>
                toggleReportTranslationDialog(true, {
                  originalTranslations: caption.translations,
                })
              }
            />
          </div>
        ))}
    </div>
  );
}

export default Captions;
