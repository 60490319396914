import "./MicToggle.css";
import { IoMic, IoMicOff } from "react-icons/io5";
import { TrackToggle } from "@livekit/components-react";
import { Track } from "livekit-client";
import { useState } from "react";

export default function MicToggle() {
  const [micEnabled, setMicEnabled] = useState(false);

  return (
    <div className="MicToggle">
      <TrackToggle
        className="Mic"
        source={Track.Source.Microphone}
        showIcon={false}
        onChange={(enabled) => setMicEnabled(enabled)}
      >
        {micEnabled ? <IoMic /> : <IoMicOff />}
      </TrackToggle>
    </div>
  );
}
