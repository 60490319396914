import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useState,
} from "react";
import {
  CaptionsState,
  LayoutState,
  LocalizationState,
  MeetingConfigState,
  MeetingState,
  UserState,
  initialCaptionsState,
  initialLayoutState,
  initialLocalizationState,
  initialMeetingConfigState,
  initialMeetingState,
  initialUserState,
} from "../types";

interface IGlobalContext {
  user: UserState;
  setUser: Dispatch<SetStateAction<UserState>>;
  layout: LayoutState;
  setLayout: Dispatch<SetStateAction<LayoutState>>;
  localization: LocalizationState;
  setLocalization: Dispatch<SetStateAction<LocalizationState>>;
  captions: CaptionsState;
  setCaptions: Dispatch<SetStateAction<CaptionsState>>;
  meeting: MeetingState;
  setMeeting: Dispatch<SetStateAction<MeetingState>>;
  meetingConfig: MeetingConfigState;
  setMeetingConfig: Dispatch<SetStateAction<MeetingConfigState>>;
}

export const GlobalContext = createContext<IGlobalContext>(
  {} as IGlobalContext
);

function GlobalContextProvider(props: { children: ReactNode }) {
  const [user, setUser] = useState<UserState>(initialUserState);
  const [layout, setLayout] = useState<LayoutState>(initialLayoutState);
  const [localization, setLocalization] = useState<LocalizationState>(
    initialLocalizationState
  );
  const [captions, setCaptions] = useState<CaptionsState>(initialCaptionsState);
  const [meeting, setMeeting] = useState<MeetingState>(initialMeetingState);
  const [meetingConfig, setMeetingConfig] = useState<MeetingConfigState>(
    initialMeetingConfigState
  );

  return (
    <GlobalContext.Provider
      value={{
        user,
        setUser,
        layout,
        setLayout,
        localization,
        setLocalization,
        captions,
        setCaptions,
        meeting,
        setMeeting,
        meetingConfig,
        setMeetingConfig,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
}

export default GlobalContextProvider;
