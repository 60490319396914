import "./ByrdhouseTabConfig.css";
import { useEffect, useState } from "react";
import { app, pages } from "@microsoft/teams-js";
import useUser from "../hooks/user";
import {
  useCreateTeamsMeeting,
  useLogin,
  useShareDeepLink,
} from "../hooks/api";
import useMeetingConfig from "../hooks/meeting-config";
import config from "./lib/config";
import { Image } from "@fluentui/react-components";
import { useToggleDialog } from "../hooks/layout";
import PreCallConfig from "./PreCallConfig";
import ContactSales from "./ContactSales";
import Loader from "./Loader";
import { LocalStorageKeys } from "../types";
import { useMeetingId } from "../hooks/teams";

export const ByrdhouseTabConfig = () => {
  const createTeamsMeeting = useCreateTeamsMeeting();
  const shareDeepLink = useShareDeepLink();

  const [meetingConfig] = useMeetingConfig();

  const [error, setError] = useState<string>();
  const meetingId = useMeetingId();

  // Set the save handler
  useEffect(() => {
    const onSaveHandler = async (saveEvent: pages.config.SaveEvent) => {
      try {
        setError(undefined);
        const context = await app.getContext();
        const host =
          config.env === "production"
            ? "https://teams.byrdhouseapp.com"
            : "https://localhost:53000";
        const entityId = "byrdhouse-tab-id";
        await pages.config.setConfig({
          contentUrl: host + "/#/tab",
          websiteUrl: host + "/#/tab",
          suggestedDisplayName: "Byrdhouse AI Translation",
          removeUrl: host + "/#/remove",
          entityId: entityId,
        });

        if (!meetingId) {
          throw new Error("Failed to get meeting id!");
        }
        await createTeamsMeeting({
          meeting_id: meetingId,
          default_voice_on: meetingConfig.default_voice_on,
        });

        if (context.chat?.id) {
          await shareDeepLink({
            // TODO: Hardcoded, may need to be updated when launched to store
            appId: "69cb19e0-cc93-4557-974b-6f48d7803655",
            meetingId: meetingId,
            chatId: context.chat.id,
            tabId: entityId,
          });
        }

        saveEvent.notifySuccess();
      } catch (error) {
        console.error(error);
        // TODO: Check error type from response
        setError(
          "You've already added Byrdhouse to this meeting, check the meeting details to use the existing app."
        );
      }
    };

    pages.config.registerOnSaveHandler(onSaveHandler);
    app.notifySuccess();
  }, [createTeamsMeeting, shareDeepLink, meetingConfig, meetingId]);

  const [user] = useUser();
  const { login, loading } = useLogin();

  useEffect(() => {
    if (user.auth) {
      pages.config.setValidityState(true);
    }
  }, [user.auth]);

  const toggleSupportDialog = useToggleDialog("support");

  if (
    (localStorage.getItem(LocalStorageKeys.EXPECT_LOGIN) && !user.auth) ||
    loading
  ) {
    return <Loader />;
  }

  if (user.auth) {
    if (user.auth.pro) {
      return <PreCallConfig error={error} />;
    }
    return <ContactSales />;
  }

  return (
    <div className="ByrdhouseTabConfig">
      <div className="TabConfigHeader">
        <Image src={"logo.png"} />
        <h1>Welcome to Byrdhouse AI 🌎</h1>
        <div>
          <h2>
            <span style={{ color: "#FFAB00" }}>
              AI-powered, real-time translation
            </span>{" "}
            in voice and captions for
          </h2>
          <h2>
            <span style={{ color: "#36B37E" }}>100+ languages</span> for your{" "}
            <span style={{ color: "#E76399" }}>
              Teams meetings, webinars, and town halls
            </span>
          </h2>
        </div>
      </div>

      <button className="GradientButton" onClick={() => login()}>
        Continue with Microsoft
      </button>
      <h2>Don't have an account?</h2>
      <button className="PinkBorderButton" onClick={() => login()}>
        Create an account
      </button>

      <div className="TabConfigFooter">
        <div>
          Having issues?{" "}
          <span
            onClick={() => toggleSupportDialog(true)}
            className="TabConfigFooterSupport"
          >
            Contact Support
          </span>
        </div>
        <div className="TabConfigFooterDivider" />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <div style={{ fontSize: "12px" }}>
            <a
              href="https://byrdhouse.notion.site/byrdhouse/Privacy-Policy-c71b7c579187429da97f8aec36b1585e"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>{" "}
            |{" "}
            <a
              href="https://byrdhouse.notion.site/Terms-of-Service-7d7aa2e356ef4f22bec82a8d885fdc4d"
              target="_blank"
              rel="noreferrer"
            >
              Terms of Service
            </a>
          </div>
          <div style={{ fontSize: "12px", fontWeight: "600" }}>
            © 2024 BYRDHOUSE INC
          </div>
        </div>
      </div>
    </div>
  );
};
