import { app } from "@microsoft/teams-js";
import { useEffect, useMemo, useState } from "react";

export const useTeamsContext = () => {
  const [teamsContext, setTeamsContext] = useState<app.Context | undefined>();

  useEffect(() => {
    const getTeamsContext = async () => {
      const context = await app.getContext();
      setTeamsContext(context);
    };
    getTeamsContext();
  }, []);

  return teamsContext;
};

export const useMeetingId = () => {
  const teamsContext = useTeamsContext();
  return useMemo(() => {
    return teamsContext?.meeting?.id || teamsContext?.channel?.id;
  }, [teamsContext]);
};
