import { useCallback, useContext, useEffect } from "react";
import { GlobalContext } from "../components/GlobalContext";
import { LocalStorageKeys } from "../types";

const localizedTextKeys = [
  "SITE_LANGUAGE",
  "YOUR_AI_POWERED_INTERPRETER",
  "CHOOSE_YOUR_LANGUAGE",
  "SESSION",
  "SPEAKER",
  "TIME",
  "JOIN",
  "EVENT_DISABLED",
  "CHECK_LATER",
  "HAVING_ISSUES",
  "CONTACT_SUPPORT",
  "PRIVACY_POLICY",
  "TERMS_OF_SERVICE",
  "VOICE",
  "LEAVE",
  "LEAVE_ARE_YOU_SURE",
  "CANCEL",
  "SURVEY_TITLE",
  "GO_TO_SURVEY",
  "DECLINE_SURVEY",
  "TEXT_TRANSLATION",
  "TEXT_CUSTOMIZATION",
  "TEXT_SPEED",
  "TEXT_TRANSLATION_DISPLAY",
  "TEXT_SIZE",
  "TEXT_COLOR",
  "TEXT",
  "VOICE_TO_VOICE",
  "LIVE",
  "SUPPORT",
  "FOR_TECHNICAL_SUPPORT",
  "FOR_BILLING_OR_OTHER_QUESTIONS",
  "OR_CALL_US_AT",
  "THIS_EVENT_HAS_ENDED",
  "CONFIRM",
  "RESUME_VOICE_TRANSLATION",
  "BROWSER_PAUSED_AUDIO",
  "LANGUAGE_SEARCH",
  "PLEASE_SELECT_LANGUAGE",
  "PRESS_TO_START_VOICE_INTERPRETATION",
] as const;

type LocalizedTextKey = (typeof localizedTextKeys)[number];

export type LocalizedText = {
  [language: string]: { [key in LocalizedTextKey]: string };
};

export const localizedText: LocalizedText = {
  en: {
    SITE_LANGUAGE: "Site Language",
    YOUR_AI_POWERED_INTERPRETER: "Your AI-powered Interpreter",
    CHOOSE_YOUR_LANGUAGE: "Choose your language (translate to)",
    SESSION: "Session",
    SPEAKER: "Speaker",
    TIME: "Time",
    JOIN: "Join",
    EVENT_DISABLED: "This event hasn't started yet.",
    CHECK_LATER: "Please check back later.",
    HAVING_ISSUES: "Having issues?",
    CONTACT_SUPPORT: "Contact support",
    PRIVACY_POLICY: "Privacy Policy",
    TERMS_OF_SERVICE: "Terms of Service",
    VOICE: "Voice",
    LEAVE_ARE_YOU_SURE: "Are you sure you want to leave the session?",
    LEAVE: "Leave",
    CANCEL: "Cancel",
    SURVEY_TITLE:
      "Thank you for using Byrdhouse AI! Could you take 30 seconds to fill out a brief survey to help us improve?",
    GO_TO_SURVEY: "Yes! Take me to the survey",
    DECLINE_SURVEY: "Sadly decline :(",
    TEXT_TRANSLATION: "Text Translation",
    TEXT_CUSTOMIZATION: "Text Customization",
    TEXT_SPEED: "Text Speed",
    TEXT_TRANSLATION_DISPLAY: "Text Translation Display",
    TEXT_SIZE: "Text Size",
    TEXT_COLOR: "Text Color",
    TEXT: "Text",
    VOICE_TO_VOICE: "Voice-to-Voice interpretation in progress...",
    LIVE: "Live",
    SUPPORT: "Support",
    FOR_TECHNICAL_SUPPORT: "For technical support:",
    FOR_BILLING_OR_OTHER_QUESTIONS: "For billing or other questions:",
    OR_CALL_US_AT: "Or call us at",
    THIS_EVENT_HAS_ENDED: "This event has ended. Thank you!",
    CONFIRM: "Confirm",
    RESUME_VOICE_TRANSLATION: "Resume Voice Translation",
    BROWSER_PAUSED_AUDIO:
      "Your browser has paused audio playback, please confirm to resume the voice translation.",
    LANGUAGE_SEARCH: "Language Search",
    PLEASE_SELECT_LANGUAGE: "Please select the language that you speak",
    PRESS_TO_START_VOICE_INTERPRETATION: "Press to start voice interpretation",
  },
  es: {
    SITE_LANGUAGE: "Idioma del sitio",
    YOUR_AI_POWERED_INTERPRETER: "Tu intérprete con tecnología AI",
    CHOOSE_YOUR_LANGUAGE: "Elige tu idioma (traducir a)",
    SESSION: "Sesión",
    SPEAKER: "Orador",
    TIME: "Hora",
    JOIN: "Unirse",
    EVENT_DISABLED: "Este evento no ha comenzado todavía.",
    CHECK_LATER: "Por favor, vuelve más tarde.",
    HAVING_ISSUES: "¿Tienes problemas?",
    CONTACT_SUPPORT: "Contactar soporte",
    PRIVACY_POLICY: "Política de privacidad",
    TERMS_OF_SERVICE: "Términos del servicio",
    VOICE: "Voz",
    LEAVE: "Salir",
    LEAVE_ARE_YOU_SURE: "¿Estás seguro de que quieres salir de la sesión?",
    CANCEL: "Cancelar",
    SURVEY_TITLE:
      "¡Gracias por usar Byrdhouse AI! ¿Podrías tomarte 30 segundos para completar una breve encuesta para ayudarnos a mejorar?",
    GO_TO_SURVEY: "¡Sí! Llévame a la encuesta",
    DECLINE_SURVEY: "Lamentablemente declinar :(",
    TEXT_TRANSLATION: "Traducción de texto",
    TEXT_CUSTOMIZATION: "Personalización de texto",
    TEXT_SPEED: "Velocidad de texto",
    TEXT_TRANSLATION_DISPLAY: "Visualización de traducción de texto",
    TEXT_SIZE: "Tamaño del texto",
    TEXT_COLOR: "Color del texto",
    TEXT: "Texto",
    VOICE_TO_VOICE: "Interpretación de voz a voz en progreso...",
    LIVE: "En vivo",
    SUPPORT: "Soporte",
    FOR_TECHNICAL_SUPPORT: "Para soporte técnico:",
    FOR_BILLING_OR_OTHER_QUESTIONS: "Para facturación u otras preguntas:",
    OR_CALL_US_AT: "O llámenos al",
    THIS_EVENT_HAS_ENDED: "Este evento ha terminado. ¡Gracias!",
    CONFIRM: "Confirmar",
    RESUME_VOICE_TRANSLATION: "Reanudar traducción de voz",
    BROWSER_PAUSED_AUDIO:
      "Tu navegador ha pausado la reproducción de audio, por favor confirma para reanudar la traducción de voz.",
    LANGUAGE_SEARCH: "Búsqueda de idioma",
    PLEASE_SELECT_LANGUAGE: "Por favor, selecciona el idioma que hablas",
    PRESS_TO_START_VOICE_INTERPRETATION:
      "Presiona para iniciar la interpretación de voz",
  },
  fr: {
    SITE_LANGUAGE: "Langue du site",
    YOUR_AI_POWERED_INTERPRETER: "Votre interprète alimenté par IA",
    CHOOSE_YOUR_LANGUAGE: "Choisissez votre langue (traduire en)",
    SESSION: "Session",
    SPEAKER: "Orateur",
    TIME: "Heure",
    JOIN: "Rejoindre",
    EVENT_DISABLED: "Cet événement n'a pas encore commencé.",
    CHECK_LATER: "Veuillez vérifier plus tard.",
    HAVING_ISSUES: "Des problèmes?",
    CONTACT_SUPPORT: "Contacter le support",
    PRIVACY_POLICY: "Politique de confidentialité",
    TERMS_OF_SERVICE: "Conditions d'utilisation",
    VOICE: "Voix",
    LEAVE: "Quitter",
    LEAVE_ARE_YOU_SURE: "Êtes-vous sûr de vouloir quitter la session?",
    CANCEL: "Annuler",
    SURVEY_TITLE:
      "Merci d'utiliser Byrdhouse AI ! Pourriez-vous prendre 30 secondes pour remplir un bref sondage afin de nous aider à nous améliorer?",
    GO_TO_SURVEY: "Oui ! Amenez-moi au sondage",
    DECLINE_SURVEY: "Décliner malheureusement :(",
    TEXT_TRANSLATION: "Traduction de texte",
    TEXT_CUSTOMIZATION: "Personnalisation du texte",
    TEXT_SPEED: "Vitesse du texte",
    TEXT_TRANSLATION_DISPLAY: "Affichage de la traduction de texte",
    TEXT_SIZE: "Taille du texte",
    TEXT_COLOR: "Couleur du texte",
    TEXT: "Texte",
    VOICE_TO_VOICE: "Interprétation voix-à-voix en cours...",
    LIVE: "En direct",
    SUPPORT: "Support",
    FOR_TECHNICAL_SUPPORT: "Pour le support technique:",
    FOR_BILLING_OR_OTHER_QUESTIONS:
      "Pour des questions de facturation ou autres:",
    OR_CALL_US_AT: "Ou appelez-nous au",
    THIS_EVENT_HAS_ENDED: "Cet événement est terminé. Merci !",
    CONFIRM: "Confirmer",
    RESUME_VOICE_TRANSLATION: "Reprendre la traduction vocale",
    BROWSER_PAUSED_AUDIO:
      "Votre navigateur a interrompu la lecture audio, veuillez confirmer pour reprendre la traduction vocale.",
    LANGUAGE_SEARCH: "Recherche de langue",
    PLEASE_SELECT_LANGUAGE: "Veuillez sélectionner la langue que vous parlez",
    PRESS_TO_START_VOICE_INTERPRETATION:
      "Appuyez pour démarrer l'interprétation vocale",
  },
};

export const useLocalization = () => {
  const context = useContext(GlobalContext);

  // Save site language to local storage
  useEffect(() => {
    localStorage.setItem(
      LocalStorageKeys.SITE_LANGUAGE,
      context.localization.siteLanguage
    );
  }, [context.localization.siteLanguage]);

  return [context.localization, context.setLocalization] as const;
};

export const useGetLocalizedText = () => {
  const [localization] = useLocalization();
  return useCallback(
    (key: LocalizedTextKey) => {
      // Default to English
      if (
        !Object.keys(localizedText).includes(localization.siteLanguage) ||
        !localizedText[localization.siteLanguage]
      ) {
        return localizedText["en"][key];
      }
      return localizedText[localization.siteLanguage][key];
    },
    [localization.siteLanguage]
  );
};
