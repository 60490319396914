import { useContext, useEffect, useState } from "react";
import { useMeetingId } from "./teams";
import { CaptionsState, LocalStorageKeys } from "../types";
import { GlobalContext } from "../components/GlobalContext";

const useCaptions = () => {
  const { captions, setCaptions } = useContext(GlobalContext);
  const [loaded, setLoaded] = useState(false);

  const meetingId = useMeetingId();

  // Load captions
  useEffect(() => {
    if (!meetingId) {
      return;
    }

    try {
      // Clear captions when meeting id changes
      const lastMeetingId = localStorage.getItem(LocalStorageKeys.MEETING_ID);
      if (!lastMeetingId || lastMeetingId !== meetingId) {
        localStorage.removeItem(LocalStorageKeys.CAPTIONS);
        localStorage.setItem(LocalStorageKeys.MEETING_ID, meetingId);
      }

      const json = localStorage.getItem(LocalStorageKeys.CAPTIONS);
      if (!json) {
        return;
      }

      setCaptions(JSON.parse(json) as CaptionsState);
    } finally {
      setLoaded(true);
    }
  }, [meetingId, setCaptions]);

  // Save captions
  useEffect(() => {
    if (!meetingId) {
      return;
    }

    if (!loaded) {
      return;
    }

    localStorage.setItem(LocalStorageKeys.CAPTIONS, JSON.stringify(captions));
  }, [meetingId, loaded, captions]);

  return [captions, setCaptions] as const;
};

export default useCaptions;
