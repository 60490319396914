import "./ContactSales.css";
import LogoHeader from "./LogoHeader";

function Banner() {
  return (
    <div className="Banner">
      <div>
        <h1>Connecting the world</h1>
        <h1>one conversation at a time</h1>
      </div>
      <div>
        <h2>
          <span style={{ color: "#FFAB00" }}>
            AI-powered, real-time translation
          </span>{" "}
          in voice and captions for{" "}
        </h2>
        <h2>
          <span style={{ color: "#36B37E" }}>100+ languages</span> for your{" "}
          <span style={{ color: "#E76399" }}>
            Teams meetings, webinars, and town halls
          </span>
        </h2>
      </div>
    </div>
  );
}

export default function ContactSales() {
  return (
    <div className="ContactSales">
      <LogoHeader />
      <Banner />
      <p>
        Users must have an active Byrdhouse account to access this integration.
        Please contact us to create a Teams integration account.
      </p>
      <button
        onClick={() => {
          window
            .open("https://calendly.com/snow-huo-byrdhouse/30min", "_blank")
            ?.focus();
        }}
        className="GradientButton"
      >
        Contact Us
      </button>
      <div className="Divider" />
      <a href="https://byrdhouseapp.com" target="_blank" rel="noreferrer">
        About Byrdhouse AI
      </a>
    </div>
  );
}
