import * as BCP47 from "bcp-47";
import emojiFlags from "emoji-flags";
import { SelectProps } from "./components/common/Select";
import { GetTeamsMeetingResponse } from "./hooks/api";

export const supportedLanguages: { [textLanguage: string]: Set<string> } = {
  ar: new Set(["ar-EG", "ar-IQ", "ar-SA", "ar-SY", "ar-YE"]),
  bn: new Set(["bn-IN"]),
  "zh-Hans": new Set(["yue-CN", "zh-CN"]),
  "zh-Hant": new Set(["zh-TW"]),
  yue: new Set(["zh-HK"]),
  cs: new Set(["cs-CZ"]),
  da: new Set(["da-DK"]),
  nl: new Set(["nl-BE", "nl-NL"]),
  en: new Set(["en-AU", "en-CA", "en-NZ", "en-SG", "en-GB", "en-US"]),
  fi: new Set(["fi-FI"]),
  fr: new Set(["fr-BE", "fr-FR", "fr-CH", "fr-CA"]),
  de: new Set(["de-AT", "de-DE", "de-CH"]),
  el: new Set(["el-GR"]),
  gu: new Set(["gu-IN"]),
  hi: new Set(["hi-IN"]),
  hu: new Set(["hu-HU"]),
  id: new Set(["id-ID"]),
  ga: new Set(["ga-IE"]),
  it: new Set(["it-IT"]),
  ja: new Set(["ja-JP"]),
  ko: new Set(["ko-KR"]),
  mr: new Set(["mr-IN"]),
  pl: new Set(["pl-PL"]),
  pt: new Set(["pt-BR"]),
  "pt-pt": new Set(["pt-PT"]),
  ro: new Set(["ro-RO"]),
  ru: new Set(["ru-RU"]),
  es: new Set(["es-AR", "es-CO", "es-MX", "es-PE", "es-ES", "es-VE"]),
  sw: new Set(["sw-KE", "sw-TZ"]),
  sv: new Set(["sv-SE"]),
  ta: new Set(["ta-IN"]),
  te: new Set(["te-IN"]),
  tr: new Set(["tr-TR"]),
  uk: new Set(["uk-UA"]),
  fa: new Set(["fa-IR"]),
};

export const languageNames: { [key: string]: string } = {
  "ar-EG": "Arabic (Egypt)",
  "ar-IQ": "Arabic (Iraq)",
  "ar-SA": "Arabic (Saudi Arabia)",
  "ar-SY": "Arabic (Syria)",
  "ar-YE": "Arabic (Yemen)",
  "bn-IN": "Bengali (India)",
  "yue-CN": "Cantonese (China)",
  "zh-CN": "Chinese (Simplified, China)",
  "zh-TW": "Chinese (Traditional, Taiwan)",
  "zh-HK": "Chinese (Traditional, Hong Kong)",
  "cs-CZ": "Czech (Czech Republic)",
  "da-DK": "Danish (Denmark)",
  "nl-BE": "Dutch (Belgium)",
  "nl-NL": "Dutch (Netherlands)",
  "en-AU": "English (Australia)",
  "en-CA": "English (Canada)",
  "en-NZ": "English (New Zealand)",
  "en-SG": "English (Singapore)",
  "en-GB": "English (United Kingdom)",
  "en-US": "English (United States)",
  "fi-FI": "Finnish (Finland)",
  "fr-BE": "French (Belgium)",
  "fr-FR": "French (France)",
  "fr-CH": "French (Switzerland)",
  "fr-CA": "French (Canada)",
  "de-AT": "German (Austria)",
  "de-DE": "German (Germany)",
  "de-CH": "German (Switzerland)",
  "el-GR": "Greek (Greece)",
  "gu-IN": "Gujarati (India)",
  "hi-IN": "Hindi (India)",
  "hu-HU": "Hungarian (Hungary)",
  "id-ID": "Indonesian (Indonesia)",
  "ga-IE": "Irish (Ireland)",
  "it-IT": "Italian (Italy)",
  "ja-JP": "Japanese (Japan)",
  "ko-KR": "Korean (South Korea)",
  "mr-IN": "Marathi (India)",
  "pl-PL": "Polish (Poland)",
  "pt-BR": "Portuguese (Brazil)",
  "pt-PT": "Portuguese (Portugal)",
  "ro-RO": "Romanian (Romania)",
  "ru-RU": "Russian (Russia)",
  "es-AR": "Spanish (Argentina)",
  "es-CO": "Spanish (Colombia)",
  "es-MX": "Spanish (Mexico)",
  "es-PE": "Spanish (Peru)",
  "es-ES": "Spanish (Spain)",
  "es-VE": "Spanish (Venezuela)",
  "sw-KE": "Swahili (Kenya)",
  "sw-TZ": "Swahili (Tanzania)",
  "sv-SE": "Swedish (Sweden)",
  "ta-IN": "Tamil (India)",
  "te-IN": "Telugu (India)",
  "tr-TR": "Turkish (Turkey)",
  "uk-UA": "Ukrainian (Ukraine)",
  "fa-IR": "Persian (Iran)",
} as const;

export const siteLanguages: SelectProps["options"] = [
  { value: "en", label: "🇺🇸 English" },
  { value: "es", label: "🇪🇸 Español" },
  { value: "fr", label: "🇫🇷 Français" },
];

export function getLanguageName(speechLanguage: string): string | undefined {
  return languageNames[speechLanguage];
}

export const getLanguageFlag = (speechLanguage: string): string => {
  // Parse language code to get country
  const schema = BCP47.parse(speechLanguage);

  if (schema.region) {
    return emojiFlags.countryCode(schema.region).emoji;
  }

  // Default return space string
  return " ";
};

export function getTextLanguage(speechLanguage: string): string | undefined {
  for (const textLanguage in supportedLanguages) {
    if (supportedLanguages[textLanguage].has(speechLanguage)) {
      return textLanguage;
    }
  }
  return undefined;
}

export enum LocalStorageKeys {
  EXPECT_LOGIN = "EXPECT_LOGIN",
  SITE_LANGUAGE = "SITE_LANGUAGE",
  AUDIO_SETTINGS = "AUDIO_SETTINGS",
  LAYOUT_SETTINGS = "LAYOUT_SETTINGS",
  CAPTIONS = "CAPTIONS",
  MEETING_ID = "MEETING_ID",
  VOICE_AVATAR = "VOICE_AVATAR",
  LIVEKIT_ID = "LIVEKIT_ID",
}

export type AudioSettings = {
  devices?: MediaDeviceInfo[];
  activeDeviceId?: string;
};

export type FirebaseAuthToken = {
  name: string;
  email: string;
  email_verified: boolean;
  exp: number;
  iat: number;
  iss: string;
  user_id: string;
  picture: string;
};

export const participantTypes = ["speaker", "interpreter", "viewer"] as const;
export const aiVoiceGenders = ["male", "female"] as const;

export type UserState = {
  auth?: {
    id: string;
    name: string;
    email: string;
    pro: boolean;
  };
  metadata: {
    participantType?: (typeof participantTypes)[number];
    selectedLanguage: string;
    voice?: "ai" | "interpreter";
    aiVoiceGender: (typeof aiVoiceGenders)[number];
  };
  livekitId: string;
  interpreter: {
    selectedLanguage?: string;
  };
};

const getLivekitId = () => {
  const existing = localStorage.getItem(LocalStorageKeys.LIVEKIT_ID);
  if (existing) {
    return existing;
  }
  const id = crypto.randomUUID().toString();
  localStorage.setItem(LocalStorageKeys.LIVEKIT_ID, id);
  return id;
};

export const initialUserState: UserState = {
  metadata: {
    selectedLanguage: Object.values(supportedLanguages)
      .flatMap((l) => Array.from(l))
      .includes(navigator.language)
      ? navigator.language
      : "en-US",
    aiVoiceGender: (localStorage.getItem(LocalStorageKeys.VOICE_AVATAR) ||
      "female") as UserState["metadata"]["aiVoiceGender"],
  },
  livekitId: getLivekitId(),
  interpreter: {},
};

export type LayoutState = {
  dialog?:
    | "mic-reminder"
    | "support"
    | "no-minutes"
    | "report-translation"
    | "unsupported";
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dialogParams?: any;
  settings: {
    textSize: number;
    textColor: {
      text: string;
      background: string;
    };
    textSpeed: number;
  };
  meeting?: GetTeamsMeetingResponse["meeting"];
};

export const initialLayoutState: LayoutState = {
  settings: localStorage.getItem(LocalStorageKeys.LAYOUT_SETTINGS)
    ? JSON.parse(localStorage.getItem(LocalStorageKeys.LAYOUT_SETTINGS)!)
    : {
        textSize: 18,
        textColor: {
          text: "#101010",
          background: "#ccc",
        },
        textSpeed: 0,
      },
};

export type LocalizationState = {
  siteLanguage: string;
};

export const initialLocalizationState: LocalizationState = {
  siteLanguage:
    localStorage.getItem(LocalStorageKeys.SITE_LANGUAGE) ||
    getTextLanguage(navigator.language) ||
    navigator.language,
};

export type Caption = {
  id: string;
  translations: { [language: string]: string };
  complete: boolean;
  name: string;
  identity: string;
};

export type CaptionsState = {
  [id: string]: Caption;
};

export const initialCaptionsState: CaptionsState = {
  // "1234": {
  //   id: "1234",
  //   complete: true,
  //   translations: { en: "Test this is a test see the test here?" },
  //   name: "Jacob",
  //   identity: "1234",
  // },
};

export type MeetingState = GetTeamsMeetingResponse["meeting"] | undefined;

export const initialMeetingState: MeetingState = undefined;

export type MeetingConfigState = Pick<
  GetTeamsMeetingResponse["meeting"],
  "default_voice_on" | "languages"
>;

export const initialMeetingConfigState: MeetingConfigState = {
  default_voice_on: true,
  languages: ["en-US", "es-MX", "zh-CN"],
};

// TODO: Global loading state
