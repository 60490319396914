import "./MicReminderDialog.css";
import Dialog from "./Dialog";
import { useCloseDialog } from "../../../hooks/layout";

type MicReminderDialogProps = {};

function MicReminderDialog(props: MicReminderDialogProps) {
  const closeDialog = useCloseDialog();
  return (
    <Dialog title={"Mic Reminder"} small={true}>
      <div className="MicReminderDialog">
        <div>
          Please mute your mic when not speaking. If left unmute, the real-time
          translation will activate automatically
        </div>
        <button className="GradientButton" onClick={closeDialog}>
          Confirm
        </button>
      </div>
    </Dialog>
  );
}

export default MicReminderDialog;
