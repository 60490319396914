import Dialog from "./Dialog";
import "./SupportDialog.css";
import { useGetLocalizedText } from "../../../hooks/localization";

type SupportDialogProps = {
  onClose: () => void;
};

function SupportDialog(props: SupportDialogProps) {
  const getLocalizedText = useGetLocalizedText();

  return (
    <Dialog title={getLocalizedText("SUPPORT")} onClose={props.onClose}>
      <div className="SupportDialog">
        <p>
          {getLocalizedText("FOR_TECHNICAL_SUPPORT")}{" "}
          <a href="mailto:jacob.greenway@byrdhouseapp.com?subject=Byrdhouse%20Support">
            jacob.greenway@byrdhouseapp.com
          </a>
        </p>
        <p>
          {getLocalizedText("FOR_BILLING_OR_OTHER_QUESTIONS")}{" "}
          <a href="mailto:snow.huo@byrdhouseapp.com?subject=Byrdhouse%20Support">
            snow.huo@byrdhouseapp.com
          </a>
        </p>
        <p>
          {getLocalizedText("OR_CALL_US_AT")}{" "}
          <a href="tel:+1-323-364-0815">+1-323-364-0815</a>
        </p>
      </div>
    </Dialog>
  );
}

export default SupportDialog;
