import "./PreCallConfig.css";
import useMeetingConfig from "../hooks/meeting-config";
import { getLanguageFlag, getLanguageName } from "../types";
import { useCallback, useEffect, useState } from "react";
import { useGetTeamsMeeting, useUpdateTeamsMeeting } from "../hooks/api";
import { Image } from "@fluentui/react-components";
import { BsQuestionCircleFill } from "react-icons/bs";
import { useToggleDialog } from "../hooks/layout";
import { ReactComponent as ConfigImg } from "../assets/config.svg";
import Loader from "./Loader";
import { toast } from "react-toastify";

type PreCallConfigProps = {
  existingMeetingId?: string;
  error?: string;
};

export default function PreCallConfig(props: PreCallConfigProps) {
  const [meetingConfig, setMeetingConfig] = useMeetingConfig();

  // Load existing meeting into config
  const getTeamsMeeting = useGetTeamsMeeting();
  const [loading, setLoading] = useState(false);
  const loadExistingTeamsMeeting = useCallback(async () => {
    if (!props.existingMeetingId) {
      return;
    }
    try {
      setLoading(true);
      const response = await getTeamsMeeting({ id: props.existingMeetingId });
      const existing = response.data.meeting;
      setMeetingConfig((prevState) => ({
        ...prevState,
        default_voice_on: existing.default_voice_on,
        languages: existing.languages,
      }));
    } finally {
      setLoading(false);
    }
  }, [props.existingMeetingId, getTeamsMeeting, setMeetingConfig]);
  useEffect(() => {
    loadExistingTeamsMeeting();
  }, [loadExistingTeamsMeeting]);

  const updateTeamsMeeting = useUpdateTeamsMeeting();
  const toggleSupportDialog = useToggleDialog("support");

  useEffect(() => {
    if (props.error) {
      window.scrollTo({ top: document.body.scrollHeight });
    }
  }, [props.error]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="PreCallConfig">
      <div className="PreCallLogo">
        <Image src={"logo.png"} /> <h1>Byrdhouse AI</h1>
      </div>
      <div className="PreCallConfigContent">
        <h1>Meeting Languages and Settings Configuration</h1>
        <div>
          <h2>Languages</h2>
          <div className="PreCallConfigLanguages">
            {meetingConfig.languages.map((language) => (
              <div key={language}>{`${getLanguageFlag(
                language
              )} ${getLanguageName(language)}`}</div>
            ))}
          </div>
        </div>
        <div className="PreCallConfigDivider" />
        <div className="PreCallConfigOption">
          <h2>Auto-enable voice-to-voice translation for all participants?</h2>
          <div>
            <input
              type="radio"
              checked={meetingConfig.default_voice_on}
              onChange={() =>
                setMeetingConfig((prevState) => ({
                  ...prevState,
                  default_voice_on: true,
                }))
              }
            />
            Yes
          </div>
          <div>
            <input
              type="radio"
              checked={!meetingConfig.default_voice_on}
              onChange={() =>
                setMeetingConfig((prevState) => ({
                  ...prevState,
                  default_voice_on: false,
                }))
              }
            />
            No, default to voice-to-text translation
          </div>
        </div>

        <ConfigImg className="ConfigImg" />

        {props.existingMeetingId && (
          <button
            onClick={async () => {
              await updateTeamsMeeting({
                id: props.existingMeetingId!,
                default_voice_on: meetingConfig.default_voice_on,
              });
              toast(
                <div className="ByrdhouseToast SuccessToast">
                  <div>{"Successfully saved meeting settings."}</div>
                </div>
              );
            }}
            className="GradientButton"
            style={{
              padding: "10px 60px",
            }}
          >
            Save
          </button>
        )}

        {props.error && <div className="PreCallConfigError">{props.error}</div>}
      </div>
      <BsQuestionCircleFill
        onClick={() => toggleSupportDialog(true)}
        className="PreCallConfigSupport"
      />
    </div>
  );
}
