// https://fluentsite.z22.web.core.windows.net/quick-start
import { FluentProvider, Spinner } from "@fluentui/react-components";
import {
  HashRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { useTeamsUserCredential } from "@microsoft/teamsfx-react";
import ByrdhouseTab from "./ByrdhouseTab";
import { TeamsFxContext } from "./Context";
import config from "./lib/config";
import { ByrdhouseTabConfig } from "./ByrdhouseTabConfig";
import { ByrdhouseTabRemove } from "./ByrdhouseTabRemove";
import GlobalContextProvider from "./GlobalContext";
import "./App.css";
import GlobalDialog from "./common/dialog/GlobalDialog";
import Settings from "./Settings";
import Permissions from "./Permissions";

// Toastify css
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export default function App() {
  const { loading, theme, themeString, teamsUserCredential } =
    useTeamsUserCredential({
      initiateLoginEndpoint: config.initiateLoginEndpoint!,
      clientId: config.clientId!,
    });
  return (
    <TeamsFxContext.Provider
      value={{ theme, themeString, teamsUserCredential }}
    >
      <GlobalContextProvider>
        <FluentProvider>
          <GlobalDialog />
          <Router>
            {loading ? (
              <Spinner style={{ margin: 100 }} />
            ) : (
              <Routes>
                <Route path="/tab" element={<ByrdhouseTab />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/permissions" element={<Permissions />} />
                <Route path="/configure" element={<ByrdhouseTabConfig />} />
                <Route path="/remove" element={<ByrdhouseTabRemove />} />
                <Route path="*" element={<Navigate to={"/tab"} />}></Route>
              </Routes>
            )}
          </Router>
        </FluentProvider>
      </GlobalContextProvider>
      <ToastContainer
        position="top-center"
        theme="dark"
        hideProgressBar={true}
        autoClose={3000}
        toastStyle={{
          borderRadius: "5px",
          padding: "5px",
        }}
      />
    </TeamsFxContext.Provider>
  );
}
