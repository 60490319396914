import "./UnsupportedDialog.css";
import Dialog from "./Dialog";

function UnsupportedDialog() {
  return (
    <Dialog title={"Unsupported Device"} small={true}>
      <div className="UnsupportedDialog">
        <div>
          Sorry, iOS devices are currently unsupported, please join the call on
          a desktop client to enjoy real-time translation.
        </div>
      </div>
    </Dialog>
  );
}

export default UnsupportedDialog;
