import { useCallback, useContext, useEffect } from "react";
import { GlobalContext } from "../components/GlobalContext";
import {
  initialUserState,
  LayoutState,
  LocalStorageKeys,
  UserState,
} from "../types";
import useUser from "./user";

const useLayout = () => {
  const { layout, setLayout } = useContext(GlobalContext);
  return [layout, setLayout] as const;
};

export const useStoreSettings = () => {
  const [layout] = useLayout();
  const [user] = useUser();
  return useEffect(() => {
    localStorage.setItem(
      LocalStorageKeys.LAYOUT_SETTINGS,
      JSON.stringify(layout.settings)
    );

    localStorage.setItem(
      LocalStorageKeys.VOICE_AVATAR,
      user.metadata.aiVoiceGender
    );
  }, [layout.settings, user.metadata.aiVoiceGender]);
};

export const useGetStoredSettings = () => {
  const [, setLayout] = useLayout();
  const [, setUser] = useUser();
  useEffect(() => {
    const onLocalStorageChanged = () => {
      const settings = JSON.parse(
        localStorage.getItem(LocalStorageKeys.LAYOUT_SETTINGS) || ""
      ) as Partial<LayoutState["settings"]>;

      if (settings.textSpeed) {
        setLayout((prevState) => ({
          ...prevState,
          settings: { ...prevState.settings, textSpeed: settings.textSpeed! },
        }));
      }

      if (settings.textColor) {
        setLayout((prevState) => ({
          ...prevState,
          settings: {
            ...prevState.settings,
            textColor: settings.textColor!,
          },
        }));
      }

      if (settings.textSize) {
        setLayout((prevState) => ({
          ...prevState,
          settings: {
            ...prevState.settings,
            textSize: settings.textSize!,
          },
        }));
      }

      const voiceAvatar =
        localStorage.getItem(LocalStorageKeys.VOICE_AVATAR) ||
        initialUserState.metadata.aiVoiceGender;
      setUser((prevState) => ({
        ...prevState,
        metadata: {
          ...prevState.metadata,
          aiVoiceGender: voiceAvatar as UserState["metadata"]["aiVoiceGender"],
        },
      }));
    };

    window.addEventListener("storage", onLocalStorageChanged);

    return () => {
      window.removeEventListener("storage", onLocalStorageChanged);
    };
  }, [setLayout, setUser]);
};

export const useToggleDialog = (
  dialog: Exclude<LayoutState["dialog"], undefined>
) => {
  const [, setLayout] = useLayout();
  return useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (show: boolean, params?: any) => {
      setLayout((prevState) => ({
        ...prevState,
        dialog: show ? dialog : undefined,
        dialogParams: show ? params : undefined,
      }));
    },
    [setLayout, dialog]
  );
};

export const useCloseDialog = () => {
  const [, setLayout] = useLayout();
  return useCallback(() => {
    setLayout((prevState) => ({
      ...prevState,
      dialog: undefined,
    }));
  }, [setLayout]);
};

export default useLayout;
