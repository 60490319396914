import "./ReportTranslationDialog.css";
import Dialog from "./Dialog";
import {
  ReportTranslationRequest,
  useReportTranslation,
} from "../../../hooks/api";
import { toast } from "react-toastify";
import { useCloseDialog } from "../../../hooks/layout";

type ReportTranslationDialogProps = {
  onClose: () => void;
  originalTranslations: ReportTranslationRequest["original_translations"];
};

export default function ReportTranslationDialog(
  props: ReportTranslationDialogProps
) {
  const reportTranslation = useReportTranslation();
  const closeDialog = useCloseDialog();
  return (
    <Dialog title={"Report Translation"} small={true} onClose={props.onClose}>
      <div className="ReportTranslationDialog">
        <div>Help us improve AI translation by reporting inaccuracies.</div>
        <div style={{ fontSize: "10px", color: "#ccc" }}>
          By submitting, you confirm that you understand the translation is
          AI-generated, and you agree to our data collection policy{" "}
          <a
            href="https://byrdhouse.notion.site/Privacy-Policy-c71b7c579187429da97f8aec36b1585e"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </div>
        <button
          className="GradientButton"
          onClick={async () => {
            try {
              await reportTranslation({
                original_translations: props.originalTranslations,
              });
              toast(
                <div className="ByrdhouseToast SuccessToast">
                  <div>{"Successfully reported translation. Thank you!"}</div>
                </div>
              );
              closeDialog();
            } catch (error) {
              console.error(error);
              toast(
                <div className="ByrdhouseToast ErrorToast">
                  <div>{"Something went wrong. Please try again."}</div>
                </div>
              );
            }
          }}
        >
          Submit
        </button>
      </div>
    </Dialog>
  );
}
