import axios from "axios";
import { useCallback, useContext, useMemo } from "react";
import { TeamsFxContext } from "../components/Context";

const useGetTeamsToken = () => {
  const teamsUserCredential = useContext(TeamsFxContext).teamsUserCredential;

  return useCallback(async () => {
    if (!teamsUserCredential) {
      throw new Error("TeamsFx SDK is not initialized!");
    }

    // Ignore errors for anonymous users
    try {
      return (await teamsUserCredential.getToken(""))!.token;
    } catch {}
  }, [teamsUserCredential]);
};

export const useAxios = () => {
  const getTeamsToken = useGetTeamsToken();

  return useMemo(() => {
    const instance = axios.create();

    // Set up the interceptor for this instance
    instance.interceptors.request.use(async (config) => {
      const token = await getTeamsToken();

      if (token && config.headers) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    });

    return instance;
  }, [getTeamsToken]);
};
