import { useCallback, useContext, useEffect, useState } from "react";
import useUser from "./user";
import { useAxios } from "./axios";
import { LocalStorageKeys } from "../types";
import config from "../components/lib/config";
import { TeamsFxContext } from "../components/Context";

const prefix = config.env === "production" ? "/api-gateway" : "";
const baseUrl =
  config.env === "production"
    ? "https://api.byrdhouseapp.com"
    : "http://localhost:8740";

export const useLogin = () => {
  const [loading, setLoading] = useState(false);
  const [, setUser] = useUser();
  const axios = useAxios();
  const teamsUserCredential = useContext(TeamsFxContext).teamsUserCredential;

  const delay = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  const login = useCallback(async () => {
    let retryCount = 0;
    let loggedIn = false;
    const maxRetries = 5;
    while (retryCount < maxRetries && !loggedIn) {
      try {
        setLoading(true);
        await delay(500 * retryCount);
        const url = new URL(`${prefix}/v1/auth/login`, baseUrl);

        const response = await axios.post(url.toString());

        const { user } = response.data as {
          user: { id: string; name: string; email: string; pro: boolean };
        };

        console.log("got user res", user);

        setUser((prevState) => ({
          ...prevState,
          auth: {
            ...prevState,
            id: user.id,
            name: user.name,
            email: user.email,
            pro: user.pro,
          },
        }));

        localStorage.setItem(LocalStorageKeys.EXPECT_LOGIN, "true");
        loggedIn = true;
      } catch (error) {
        // Retry with consent screen if fails first time
        if (retryCount === 0) {
          await teamsUserCredential?.login(["User.Read", "ChatMessage.Send"]);
        }
        if (retryCount >= maxRetries) {
          throw error;
        }
      } finally {
        retryCount++;
        setLoading(false);
      }
    }
    setLoading(false);
  }, [axios, setUser, teamsUserCredential]);

  // Login user automatically with expected login
  useEffect(() => {
    const expectLogin = localStorage.getItem(LocalStorageKeys.EXPECT_LOGIN);
    if (expectLogin) {
      login();
    }
  }, [login]);

  return { login, loading };
};

export type GenerateLivekitTokenRequest = {
  meeting_id: string;
  identity: string;
};

export type GenerateLivekitTokenResponse = {
  encodedToken: string;
};

export const useGenerateLivekitToken = () => {
  const axios = useAxios();
  return useCallback(
    async (request: GenerateLivekitTokenRequest) => {
      const url = new URL(`${prefix}/v1/auth/livekitToken`, baseUrl);
      return await axios.post<GenerateLivekitTokenResponse>(
        url.toString(),
        request
      );
    },
    [axios]
  );
};

export type CreateTeamsMeetingRequest = {
  meeting_id: string;
  default_voice_on: boolean;
};

export const useCreateTeamsMeeting = () => {
  const axios = useAxios();
  return useCallback(
    async (request: CreateTeamsMeetingRequest) => {
      const url = new URL(`${prefix}/v1/teams/meeting`, baseUrl);
      return await axios.post(url.toString(), request);
    },
    [axios]
  );
};

export type GetTeamsMeetingRequest = {
  id: string;
};

export type GetTeamsMeetingResponse = {
  meeting: {
    id: string;
    enabled: boolean;
    host_id: string;
    languages: string[];
    default_voice_on: boolean;
    room_id: string;
    created_at: string;
  };
};

export const useGetTeamsMeeting = () => {
  const axios = useAxios();
  return useCallback(
    async (request: GetTeamsMeetingRequest) => {
      const url = new URL(`${prefix}/v1/teams/meeting/${request.id}`, baseUrl);
      return await axios.get<GetTeamsMeetingResponse>(url.toString());
    },
    [axios]
  );
};

export type UpdateTeamsMeetingRequest = {
  id: string;
  default_voice_on: boolean;
};

export type UpdateTeamsMeetingResponse = GetTeamsMeetingResponse;

export const useUpdateTeamsMeeting = () => {
  const axios = useAxios();
  return useCallback(
    async (request: UpdateTeamsMeetingRequest) => {
      const url = new URL(
        `${prefix}/v1/teams/meeting/${request.id}/update`,
        baseUrl
      );
      return await axios.post<UpdateTeamsMeetingResponse>(
        url.toString(),
        request
      );
    },
    [axios]
  );
};

export type DeleteTeamsMeetingRequest = {
  id: string;
};

export const useDeleteTeamsMeeting = () => {
  const axios = useAxios();
  return useCallback(
    async (request: DeleteTeamsMeetingRequest) => {
      const url = new URL(`${prefix}/v1/teams/meeting/${request.id}`, baseUrl);
      return await axios.delete(url.toString());
    },
    [axios]
  );
};

export type ShareDeepLinkRequest = {
  appId: string;
  meetingId: string;
  chatId: string;
  tabId: string;
};

export const useShareDeepLink = () => {
  const axios = useAxios();

  return useCallback(
    async (request: ShareDeepLinkRequest) => {
      const url = new URL(
        `${prefix}/v1/teams/meeting/${request.meetingId}/shareDeepLink`,
        baseUrl
      );
      return await axios.post(url.toString(), request);
    },
    [axios]
  );
};

export type GetTranslationSecondsRemainingRequest = {
  meeting_id: string;
};

export type GetTranslationSecondsRemainingResponse = {
  translation_seconds_remaining: number;
};

export const useGetTranslationSecondsRemaining = () => {
  const axios = useAxios();

  return useCallback(
    async (request: GetTranslationSecondsRemainingRequest) => {
      const url = new URL(
        `${prefix}/v1/teams/meeting/${request.meeting_id}/translationSecondsRemaining`,
        baseUrl
      );
      return await axios.get(url.toString());
    },
    [axios]
  );
};

type Translations = {
  [language: string]: string;
};

export type ReportTranslationRequest = {
  original_translations: Translations;
};

export const useReportTranslation = () => {
  const axios = useAxios();
  return useCallback(
    async (request: ReportTranslationRequest) => {
      const url = new URL(`${prefix}/v1/report`, baseUrl);
      return await axios.post(url.toString(), request);
    },
    [axios]
  );
};
