import useLayout, { useCloseDialog } from "../../../hooks/layout";
import MicReminderDialog from "./MicReminderDialog";
import NoMinutesDialog from "./NoMinutesDialog";
import ReportTranslationDialog from "./ReportTranslationDialog";
import SupportDialog from "./SupportDialog";
import UnsupportedDialog from "./UnsupportedDialog";

function GlobalDialog() {
  const [layout] = useLayout();

  const closeDialog = useCloseDialog();

  switch (layout.dialog) {
    case "mic-reminder": {
      return <MicReminderDialog />;
    }
    case "support": {
      return <SupportDialog onClose={closeDialog} />;
    }
    case "no-minutes": {
      return <NoMinutesDialog />;
    }
    case "report-translation": {
      return (
        <ReportTranslationDialog
          onClose={closeDialog}
          originalTranslations={layout.dialogParams.originalTranslations}
        />
      );
    }
    case "unsupported": {
      return <UnsupportedDialog />;
    }
  }

  return null;
}

export default GlobalDialog;
