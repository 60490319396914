import { IoChevronDown, IoChevronUp, IoSearch } from "react-icons/io5";
import "./Select.css";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

export type SelectOption = {
  value?: string;
  label: string;
  titleLabel?: string;
};

export type OptionGroup = {
  label: string;
  options: SelectOption[];
};

export type SelectProps = {
  title?: string | React.ReactNode;
  selected: SelectOption["value"] | SelectOption["value"][];
  options: SelectOption[];
  onChange: (value: SelectOption["value"]) => void;
  className?: string;
  hideToggle?: boolean;
  style?: React.CSSProperties;
  titleStyle?: React.CSSProperties;
  optionsVerticalPosition?: "above" | "below";
  optionsHorizontalPosition?: "left" | "right";
  search?: {
    placeholder: string;
  };
  optionGroups?: OptionGroup[];
};

function Select(props: SelectProps) {
  const [showOptions, setShowOptions] = useState(false);

  const selectRef = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (
      selectRef.current &&
      (!event.target ||
        (event.target instanceof Element &&
          !selectRef.current.contains(event.target)))
    ) {
      setShowOptions(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  const isMultiSelect = useMemo(() => {
    return Array.isArray(props.selected);
  }, [props.selected]);

  const selectedOption = useMemo(() => {
    return props.options.find((o) => o.value === props.selected);
  }, [props.options, props.selected]);

  const [query, setQuery] = useState("");

  const renderOption = useCallback(
    (option: SelectOption) => {
      return (
        <div key={option.value} onClick={() => props.onChange(option.value)}>
          <input
            type={!isMultiSelect ? "radio" : "checkbox"}
            readOnly={true}
            checked={
              !!props.selected &&
              !!option.value &&
              (!isMultiSelect
                ? option.value === props.selected
                : props.selected.includes(option.value))
            }
          />
          <div>{option.label}</div>
        </div>
      );
    },
    [props, isMultiSelect]
  );

  return (
    <div
      className={`Select ${props.className ? props.className : ""}`}
      onClick={() => setShowOptions((prevState) => !prevState)}
      ref={selectRef}
      style={props.style}
    >
      {showOptions && (
        <div
          className={`Options ${
            props.optionsVerticalPosition ? props.optionsVerticalPosition : ""
          } ${
            props.optionsHorizontalPosition
              ? props.optionsHorizontalPosition
              : ""
          }`}
        >
          {!!props.search && (
            <div
              className="Search"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <IoSearch />
              <input
                type="text"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                placeholder={props.search.placeholder}
              />
            </div>
          )}
          {props.optionGroups &&
            props.optionGroups.map((group) => (
              <>
                <div className="OptionGroupLabel" key={group.label}>
                  {group.label}
                </div>
                {group.options
                  .filter((option) =>
                    option.label
                      .toLocaleLowerCase()
                      .includes(query.toLocaleLowerCase())
                  )
                  .map((option) => renderOption(option))}
              </>
            ))}
          {props.options
            .filter((option) => option.value)
            .filter(
              (option) =>
                !(props.optionGroups || [])
                  .flatMap((group) => group.options)
                  .map((option) => option.value)
                  .includes(option.value)
            )
            .filter((option) =>
              option.label
                .toLocaleLowerCase()
                .includes(query.toLocaleLowerCase())
            )
            .map((option) => renderOption(option))}
        </div>
      )}
      <div className="SelectTitle" style={props.titleStyle}>
        {props.title || selectedOption?.titleLabel || selectedOption?.label}
      </div>
      {!props.hideToggle && (showOptions ? <IoChevronUp /> : <IoChevronDown />)}
    </div>
  );
}

export default Select;
