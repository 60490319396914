import useLayout from "../../hooks/layout";
import "./TextSpeed.css";

function TextSpeed() {
  const [layout, setLayout] = useLayout();
  return (
    <div className="TextSpeed">
      Text Speed
      <div className="TextSpeedSlider">
        Live
        <input
          type="range"
          min={0}
          max={5}
          value={layout.settings.textSpeed}
          onChange={(e) =>
            setLayout((prevState) => ({
              ...prevState,
              settings: {
                ...prevState.settings,
                textSpeed: Number(e.target.value),
              },
            }))
          }
        />
        5s
      </div>
    </div>
  );
}

export default TextSpeed;
