import {
  useConnectionState,
  useParticipantTracks,
  useRemoteParticipants,
  useRoomContext,
} from "@livekit/components-react";
import "./Voice.css";
import { useEffect, useMemo, useRef } from "react";
import { RemoteTrackPublication, Track } from "livekit-client";
import useUser from "../hooks/user";
import VoiceOn from "../assets/voice-on.svg";
import VoiceOff from "../assets/voice-off.svg";
import { UserState } from "../types";

function Voice() {
  const connectionState = useConnectionState();
  const room = useRoomContext();
  const [user, setUser] = useUser();

  const remoteParticipants = useRemoteParticipants();

  const interpreter = useMemo(() => {
    return remoteParticipants.find((participant) => {
      if (!participant.metadata) {
        return false;
      }
      const metadata = JSON.parse(participant.metadata) as {
        language: UserState["metadata"]["selectedLanguage"];
        participant_type: UserState["metadata"]["participantType"];
      };
      if (
        metadata.participant_type === "interpreter" &&
        metadata.language === user.metadata.selectedLanguage
      ) {
        return true;
      }
      return false;
    });
  }, [remoteParticipants, user.metadata.selectedLanguage]);

  const interpreterTracks = useParticipantTracks(
    [Track.Source.Microphone],
    interpreter?.identity
  );

  const interpreterTrackSid = useMemo(() => {
    if (!interpreter) {
      return;
    }
    const interpreterTrack = interpreterTracks.find(
      (t) => t.participant.sid === interpreter.sid
    );
    if (!interpreterTrack) {
      return;
    }
    return interpreterTrack.publication.trackSid;
  }, [interpreter, interpreterTracks]);

  // Switch to interpreter when available and use ai as fallback
  useEffect(() => {
    setUser((prevState) => {
      // Switch to interpreter when they join
      if (prevState.metadata.voice === "ai" && interpreter) {
        return {
          ...prevState,
          metadata: {
            ...prevState.metadata,
            voice: "interpreter",
          },
        };
      }
      // Switch to ai when interpreter leaves
      if (prevState.metadata.voice === "interpreter" && !interpreter) {
        return {
          ...prevState,
          metadata: {
            ...prevState.metadata,
            voice: "ai",
          },
        };
      }
      return prevState;
    });
  }, [interpreter, setUser]);

  useEffect(() => {
    function handlePublication(publication: RemoteTrackPublication) {
      const subscribeToInterpreter =
        !!interpreter &&
        user.metadata.voice !== undefined &&
        !!interpreterTrackSid &&
        interpreterTrackSid === publication.trackSid;
      const subscribeToAIVoice =
        !interpreter &&
        user.metadata.voice !== undefined &&
        publication.trackName === `ai-voice-${user.metadata.selectedLanguage}`;
      publication.setSubscribed(subscribeToInterpreter || subscribeToAIVoice);
    }

    if (connectionState === "connected") {
      room.remoteParticipants.forEach((participant) => {
        participant.audioTrackPublications.forEach((publication) => {
          handlePublication(publication);
        });
      });
    }

    function onTrackPublished(publication: RemoteTrackPublication) {
      if (connectionState === "connected") {
        handlePublication(publication);
      }
    }

    room.on("trackPublished", onTrackPublished);
    return () => {
      room.off("trackPublished", onTrackPublished);
    };
  }, [
    user.metadata.selectedLanguage,
    user.metadata.voice,
    connectionState,
    room,
    interpreter,
    interpreterTrackSid,
  ]);

  const autoplayAttempted = useRef(false);

  useEffect(() => {
    async function attemptAutoplay() {
      await room.startAudio();
    }

    if (!autoplayAttempted.current && user.metadata.voice !== undefined) {
      autoplayAttempted.current = true;
      attemptAutoplay();
    }
  }, [user.metadata.voice, room, setUser]);

  return (
    <button
      style={{
        color: user.metadata.voice !== undefined ? "white" : "gray",
      }}
      className={
        user.metadata.voice !== undefined
          ? "Voice PinkBorderButton"
          : "Voice GrayBorderButton"
      }
      onClick={() => {
        setUser((prevState) => {
          if (prevState.metadata.voice === undefined) {
            room.startAudio();
          }
          return {
            ...prevState,
            metadata: {
              ...prevState.metadata,
              voice:
                prevState.metadata.voice !== undefined
                  ? undefined
                  : interpreter
                  ? "interpreter"
                  : "ai",
            },
          };
        });
      }}
    >
      {user.metadata.voice !== undefined ? (
        <>
          <img src={VoiceOn} alt="Voice On" />
        </>
      ) : (
        <>
          <img src={VoiceOff} alt="Voice Off" />
        </>
      )}
    </button>
  );
}

export default Voice;
