import { useEffect } from "react";
import { pages } from "@microsoft/teams-js";
import { useDeleteTeamsMeeting } from "../hooks/api";
import "./ByrdhouseTabRemove.css";
import { useMeetingId } from "../hooks/teams";

/**
 * Implementation of Byrdhouse Tab remove page
 */
export const ByrdhouseTabRemove = () => {
  const deleteTeamsMeeting = useDeleteTeamsMeeting();
  const meetingId = useMeetingId();

  useEffect(() => {
    const onRemoveHandler = async (event: pages.config.RemoveEvent) => {
      if (!meetingId) {
        throw new Error("Failed to get meeting id!");
      }
      await deleteTeamsMeeting({ id: meetingId });
      event.notifySuccess();
    };
    pages.config.registerOnRemoveHandler(onRemoveHandler);
    pages.config.setValidityState(true);
  }, [deleteTeamsMeeting, meetingId]);

  return (
    <div className="ByrdhouseTabRemove">
      <h1>Remove Byrdhouse AI About You're about to remove your tab... </h1>
      <p>
        Are you sure you want to remove this tab? You'll lose access to
        real-time translation for your meetings. You can always add the
        Byrdhouse AI translation tab again later if needed.
      </p>
    </div>
  );
};
