import { useContext, useEffect } from "react";
import { GlobalContext } from "../components/GlobalContext";
import {
  useConnectionState,
  useLocalParticipant,
} from "@livekit/components-react";
import { TeamsFxContext } from "../components/Context";
import { useData } from "@microsoft/teamsfx-react";

const useUser = () => {
  const { user, setUser } = useContext(GlobalContext);
  return [user, setUser] as const;
};

export const useUserMetadata = () => {
  const [user] = useUser();
  const connectionState = useConnectionState();
  const { localParticipant } = useLocalParticipant();

  const { teamsUserCredential } = useContext(TeamsFxContext);
  const { loading, data, error } = useData(async () => {
    if (teamsUserCredential) {
      const userInfo = await teamsUserCredential.getUserInfo();
      return userInfo;
    }
  });
  const name = loading || error ? "" : data!.displayName;

  useEffect(() => {
    if (connectionState === "connected") {
      localParticipant.setMetadata(
        JSON.stringify({
          participant_type: "speaker",
          language: user.metadata.selectedLanguage,
          voice: user.metadata.voice,
          ai_voice_gender: user.metadata.aiVoiceGender,
          name: name,
        })
      );
    }
  }, [connectionState, localParticipant, user, name]);
};

export default useUser;
