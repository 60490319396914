import "./LanguageSelect.css";
import useUser from "../hooks/user";
import { useMemo, useState } from "react";
import { getLanguageFlag, getLanguageName } from "../types";
import Select, { SelectProps } from "./common/Select";
import { useGetLocalizedText } from "../hooks/localization";

type LanguageSelectProps = {
  languages: string[];
  preJoin?: boolean;
  interpreter?: boolean;
  optionsVerticalPosition?: SelectProps["optionsVerticalPosition"];
  optionsHorizontalPosition?: SelectProps["optionsHorizontalPosition"];
};

const topLanguages = [
  "en-US",
  "zh-CN",
  "es-MX",
  "it-IT",
  "ja-JP",
  "fr-FR",
  "pt-BR",
  "de-DE",
  "tr-TR",
  "ru-RU",
];

function LanguageSelect(props: LanguageSelectProps) {
  const getLocalizedText = useGetLocalizedText();
  const [user, setUser] = useUser();

  const includedTopLanguages = useMemo(() => {
    return props.languages
      .filter((l) => topLanguages.includes(l))
      .sort((a, b) => topLanguages.indexOf(a) - topLanguages.indexOf(b));
  }, [props.languages]);

  const selectedLanguage = useMemo(() => {
    let language = user.metadata.selectedLanguage;
    if (
      props.languages.length &&
      !props.languages.includes(user.metadata.selectedLanguage)
    ) {
      // Default to first in list
      language = props.languages[0];
      setUser((prevState) => ({
        ...prevState,
        metadata: {
          ...prevState.metadata,
          selectedLanguage: language,
        },
      }));
    }
    if (props.interpreter) {
      return user.interpreter.selectedLanguage;
    }
    return language;
  }, [
    setUser,
    props.languages,
    user.metadata.selectedLanguage,
    props.interpreter,
    user.interpreter.selectedLanguage,
  ]);

  const [, setShowTooltip] = useState(false);

  return (
    <>
      <Select
        className={props.preJoin ? "LanguageSelectPreJoin" : "LanguageSelect"}
        selected={selectedLanguage}
        optionsVerticalPosition={props.optionsVerticalPosition}
        optionsHorizontalPosition={props.optionsHorizontalPosition}
        onChange={(value) => {
          setShowTooltip(false);
          if (value) {
            setUser((prevState) => ({
              ...prevState,
              metadata: {
                ...prevState.metadata,
                selectedLanguage: value,
              },
              ...(props.interpreter
                ? {
                    interpreter: {
                      ...prevState.interpreter,
                      selectedLanguage: value,
                    },
                  }
                : {}),
            }));
          }
        }}
        search={
          props.languages.length >= 10
            ? { placeholder: getLocalizedText("LANGUAGE_SEARCH") }
            : undefined
        }
        options={[
          {
            value: undefined,
            label: "Click to select your target language",
          },
          ...props.languages.map((language) => ({
            value: language,
            label: getLanguageFlag(language) + " " + getLanguageName(language)!,
            titleLabel: getLanguageName(language),
          })),
        ]}
        optionGroups={
          props.languages.length >= 10 && includedTopLanguages.length > 0
            ? [
                {
                  label: "Top Languages",
                  options: includedTopLanguages.map((language) => ({
                    value: language,
                    label:
                      getLanguageFlag(language) +
                      " " +
                      getLanguageName(language)!,
                    titleLabel: getLanguageName(language),
                  })),
                },
                {
                  label: "Other Languages",
                  options: props.languages
                    .filter((l) => !includedTopLanguages.includes(l))
                    .map((language) => ({
                      value: language,
                      label:
                        getLanguageFlag(language) +
                        " " +
                        getLanguageName(language)!,
                      titleLabel: getLanguageName(language),
                    })),
                },
              ]
            : undefined
        }
      />
    </>
  );
}

export default LanguageSelect;
