import "./LivekitConnection.css";
import {
  LiveKitRoom,
  RoomAudioRenderer,
  useLocalParticipant,
  useMediaDeviceSelect,
} from "@livekit/components-react";
import "@livekit/components-styles";
import {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Captions from "./Captions";
import useUser, { useUserMetadata } from "../hooks/user";
import Voice from "./Voice";
import LanguageSelect from "./LanguageSelect";
import { AudioSettings, getTextLanguage, LocalStorageKeys } from "../types";
import useCaptions from "../hooks/captions";
import MicToggle from "./MicToggle";
import useMeeting from "../hooks/meeting";
import { IoCheckmark, IoEllipsisVertical } from "react-icons/io5";
import {
  useGenerateLivekitToken,
  useGetTranslationSecondsRemaining,
  useLogin,
  useShareDeepLink,
} from "../hooks/api";
import { useMeetingId, useTeamsContext } from "../hooks/teams";
import { FaRegShareFromSquare } from "react-icons/fa6";
import * as teams from "@microsoft/teams-js";
import { getBrowser, LocalAudioTrack } from "livekit-client";
import { useGetStoredSettings, useToggleDialog } from "../hooks/layout";
import config from "./lib/config";
import { BsQuestionCircleFill } from "react-icons/bs";
import { Image } from "@fluentui/react-components";
import Loader from "./Loader";

function App() {
  useUserMetadata();

  const [captions] = useCaptions();
  const [user, setUser] = useUser();
  const { localParticipant } = useLocalParticipant();

  const pipPadding = 20;
  const pipFontGap = 10;
  const totalLinesToRender = 4;
  const pipWidth = 600;
  const pipFontSize = 24;
  const pipHeight =
    pipPadding * 2 +
    pipFontSize * totalLinesToRender +
    pipFontGap * (totalLinesToRender - 1);

  const [overlayActive, setOverlayActive] = useState(false);

  // Render captions to the canvas for pip mode
  const canvas = createRef<HTMLCanvasElement>();
  useEffect(() => {
    let animationFrameId: number | undefined = undefined;
    const render = async () => {
      if (!canvas.current) {
        return;
      }

      const scale = window.devicePixelRatio;
      canvas.current.width = Math.floor(pipWidth * scale);
      canvas.current.height = Math.floor(pipHeight * scale);

      const context = canvas.current.getContext("2d");
      if (!context) {
        return;
      }
      // Normalize coordinate system to use CSS pixels.
      context.scale(scale, scale);

      context.fillStyle = "#101010";
      context.fillRect(0, 0, canvas.current.width, canvas.current.height);

      // Determine which captions to render
      const lastCaptions = Object.values(captions)
        // Filter captions for local participant
        .filter((caption) => caption.identity !== localParticipant.identity)
        .slice(-totalLinesToRender);

      const textLanguage = getTextLanguage(user.metadata.selectedLanguage);
      if (!textLanguage) {
        return;
      }

      const captionsToRender = lastCaptions
        .filter((caption) => !!caption.translations[textLanguage])
        .map(
          (caption) =>
            `${caption.name.split(" ")[0] || caption.name}:\t${
              caption.translations[textLanguage]
            }`
        );

      context.fillStyle = "white";
      context.font = `${pipFontSize}px sans-serif`;
      context.textAlign = "left";
      context.textBaseline = "bottom";
      context.textRendering = "optimizeLegibility";

      captionsToRender
        .reduce((result: string[], text: string) => {
          let currentLine = "";
          const words = text.split(" ");
          for (let word of words) {
            // If entire word takes line, split by char
            const measureWord = context.measureText(word);
            if (measureWord.width > pipWidth - pipPadding * 2) {
              for (const char of word) {
                const measure = context.measureText(currentLine + char);
                if (measure.width > pipWidth - pipPadding * 2) {
                  result.push(currentLine);
                  currentLine = char;
                } else {
                  currentLine += char;
                }
              }
            }
            // Split by empty space
            else {
              const measure = context.measureText(currentLine + word);
              if (measure.width > pipWidth - pipPadding * 2) {
                result.push(currentLine.trim());
                currentLine = word + " ";
              } else {
                currentLine += word + " ";
              }
            }
          }

          if (currentLine.length > 0) {
            result.push(currentLine.trim());
          }

          return result;
        }, [])
        .slice(-totalLinesToRender)
        .forEach((line, index) => {
          context.fillStyle = "white";
          context.fillText(
            line,
            pipPadding,
            pipPadding + (index + 1) * pipFontSize + index * pipFontGap
          );
          // Debug lines
          // context.fillStyle = "red";
          // context.fillRect(pipPadding, 0, 1, pipHeight);
          // context.fillRect(pipWidth - pipPadding, 0, 1, pipHeight);
          // context.fillRect(
          //   0,
          //   pipPadding + index * pipFontSize + index * pipFontGap,
          //   canvas.current?.width || 0,
          //   1
          // );
          // context.fillRect(
          //   0,
          //   (index + 1) * pipFontSize + pipPadding + index * pipFontGap,
          //   canvas.current?.width || 0,
          //   1
          // );
        });

      animationFrameId = requestAnimationFrame(render);
    };
    render();
    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, [
    canvas,
    captions,
    user.metadata.selectedLanguage,
    pipFontSize,
    pipPadding,
    pipWidth,
    pipHeight,
    localParticipant.identity,
  ]);

  const toggleOverlay = useCallback(() => {
    if (!canvas.current) {
      return;
    }
    const stream = canvas.current.captureStream();
    const videoElement = document.createElement("video");
    videoElement.hidden = true;
    videoElement.muted = true;
    videoElement.autoplay = true;
    videoElement.srcObject = stream;

    const onRemove = () => {
      stream.getTracks().forEach((t) => t.stop());
      videoElement.remove();
      setOverlayActive(false);
    };

    videoElement.onloadedmetadata = async () => {
      videoElement.onleavepictureinpicture = () => {
        onRemove();
      };
      try {
        await videoElement.requestPictureInPicture();
        setOverlayActive(true);
      } catch {
        onRemove();
      }
    };

    videoElement.play();
  }, [canvas]);

  const [meeting] = useMeeting();

  useEffect(() => {
    if (meeting?.default_voice_on) {
      setUser((prevState) => ({
        ...prevState,
        metadata: {
          ...prevState.metadata,
          voice: "ai",
        },
      }));
    }
  }, [meeting?.default_voice_on, setUser]);

  useGetStoredSettings();

  const { login } = useLogin();
  const teamsContext = useTeamsContext();
  const shareDeepLink = useShareDeepLink();
  const [shared, setShared] = useState(false);
  const sharedTimeout = useRef<NodeJS.Timeout>();
  const meetingId = useMeetingId();
  const clickShare = useCallback(async () => {
    // Require login before share
    await login();

    if (!teamsContext || !meetingId || !teamsContext.chat) {
      return;
    }
    clearTimeout(sharedTimeout.current);
    setShared(true);
    sharedTimeout.current = setTimeout(() => {
      setShared(false);
    }, 2 * 1000);
    await shareDeepLink({
      // TODO: Hardcoded, may need to be updated when launched to store
      appId: "69cb19e0-cc93-4557-974b-6f48d7803655",
      meetingId: meetingId,
      chatId: teamsContext.chat.id,
      tabId: teamsContext.page.id,
    });
  }, [shareDeepLink, teamsContext, meetingId, login]);

  useEffect(() => {
    if (meetingId) {
      console.log("Teams meeting id", meetingId);
    }
  }, [meetingId]);

  const { microphoneTrack } = useLocalParticipant();
  const { devices, activeDeviceId, setActiveMediaDevice } =
    useMediaDeviceSelect({
      kind: "audioinput",
      track: (microphoneTrack?.audioTrack as LocalAudioTrack) || undefined,
      requestPermissions: true,
    });

  useEffect(() => {
    const getPermissions = async () => {
      const permissions = await navigator.permissions.query({
        // @ts-ignore
        name: "microphone",
      });
      if (
        teamsContext?.app.host.clientType === "web" &&
        permissions.state !== "granted"
      ) {
        teams.dialog.url.open({
          url: `${
            config.env === "production"
              ? "https://teams.byrdhouseapp.com"
              : "https://localhost:53000"
          }/#/permissions`,
          size: {
            width: 600,
            height: 400,
          },
        });
      }
    };
    getPermissions();
  }, [teamsContext]);

  const openSettingsDialog = useCallback(() => {
    localStorage.setItem(
      LocalStorageKeys.AUDIO_SETTINGS,
      JSON.stringify({
        activeDeviceId: activeDeviceId,
        devices: devices,
      })
    );
    teams.dialog.url.open({
      url: `${
        config.env === "production"
          ? "https://teams.byrdhouseapp.com"
          : "https://localhost:53000"
      }/#/settings`,
      size: {
        width: 600,
        height: 400,
      },
    });
  }, [activeDeviceId, devices]);

  useEffect(() => {
    const onLocalStorageChanged = () => {
      const audioSettings = JSON.parse(
        localStorage.getItem(LocalStorageKeys.AUDIO_SETTINGS) || ""
      ) as AudioSettings;
      if (audioSettings.activeDeviceId) {
        setActiveMediaDevice(audioSettings.activeDeviceId);
      }
    };

    window.addEventListener("storage", onLocalStorageChanged);

    return () => {
      window.removeEventListener("storage", onLocalStorageChanged);
    };
  }, [setActiveMediaDevice]);

  const isMeetingStage = useMemo(() => {
    return (
      !!teamsContext &&
      teamsContext.page.frameContext === teams.FrameContexts.meetingStage
    );
  }, [teamsContext]);

  const toggleSupportDialog = useToggleDialog("support");

  const supportToggle = (
    <BsQuestionCircleFill
      onClick={() => toggleSupportDialog(true)}
      className="SupportToggle"
    />
  );

  const settingsToggle = (
    <IoEllipsisVertical
      className="SettingsToggle"
      onClick={openSettingsDialog}
    />
  );

  const getTranslationSecondsRemaining = useGetTranslationSecondsRemaining();
  useEffect(() => {
    if (!meeting) {
      return;
    }

    const request = async () => {
      const response = await getTranslationSecondsRemaining({
        meeting_id: meeting.id,
      });
      console.debug(response);
    };

    const interval = setInterval(request, 10 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, [meeting, getTranslationSecondsRemaining]);

  // useEffect(() => {
  //   if (teamsContext?.page.frameContext === teams.FrameContexts.sidePanel) {
  // teams.meeting.requestAppAudioHandling(
  //   {
  //     isAppHandlingAudio: true,
  //     micMuteStateChangedCallback: async (micState) => {
  //       console.log("got mic state", micState);
  //       return micState;
  //     },
  //   },
  //   () => {}
  // );
  //   }
  // }, [teamsContext]);

  if (!meeting) {
    return <Loader />;
  }

  return (
    <div
      className={`LivekitConnection ${isMeetingStage ? "MeetingStage" : ""}`}
    >
      {/* Hidden pip canvas for rendering captions */}
      <canvas
        style={{ display: "none" }}
        ref={canvas}
        width={pipWidth}
        height={pipHeight}
      />
      <RoomAudioRenderer />

      <div className="Header">
        {isMeetingStage ? (
          <div className="Logo">
            <Image src={"logo.png"} /> <h1>Byrdhouse AI</h1>
          </div>
        ) : (
          <MicToggle />
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: isMeetingStage ? "20px" : "4px",
            marginLeft: isMeetingStage ? "auto" : undefined,
          }}
        >
          {teamsContext?.user?.licenseType !== "Anonymous" && (
            <button
              className="PinkBorderButton"
              onClick={clickShare}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "4px",
                fontSize: "12px",
              }}
            >
              {!shared ? (
                <>
                  Share <FaRegShareFromSquare />
                </>
              ) : (
                <>
                  Shared <IoCheckmark />
                </>
              )}
            </button>
          )}
          {isMeetingStage ? supportToggle : settingsToggle}
        </div>
      </div>
      <Captions hide={false} />
      <div className="Controls">
        <LanguageSelect languages={meeting.languages} />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "4px",
          }}
        >
          <Voice />
          {isMeetingStage && <MicToggle />}
          <button
            className={overlayActive ? "PinkBorderButton" : "GrayBorderButton"}
            style={{
              minWidth: "fit-content",
              textWrap: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontSize: "12px",
              color: overlayActive ? "white" : "gray",
            }}
            onClick={toggleOverlay}
          >
            Overlay
          </button>
        </div>
        {isMeetingStage && settingsToggle}
      </div>
    </div>
  );
}

export function LiveKitConnection() {
  const [user] = useUser();
  const [meeting] = useMeeting();
  const [token, setToken] = useState<string>();

  const generateLivekitToken = useGenerateLivekitToken();
  useEffect(() => {
    if (!meeting || !meeting.enabled) {
      return;
    }
    const getToken = async () => {
      const response = await generateLivekitToken({
        meeting_id: meeting.id,
        identity: user.livekitId,
      });

      setToken(response.data.encodedToken);
    };
    getToken();
    setInterval(() => {
      getToken();
    }, 9 * 1000 * 60);
  }, [meeting, user.livekitId, generateLivekitToken]);

  const toggleUnsupportedDialog = useToggleDialog("unsupported");
  useEffect(() => {
    if (getBrowser()?.os === "iOS") {
      toggleUnsupportedDialog(true);
    }
  }, [toggleUnsupportedDialog]);

  if (!token) {
    return <Loader />;
  }

  return (
    <LiveKitRoom
      audio={true}
      token={token}
      serverUrl={config.livekitUrl}
      connect={true}
      connectOptions={{ autoSubscribe: false }}
    >
      <App />
    </LiveKitRoom>
  );
}
