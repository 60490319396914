import { useEffect, useState } from "react";
import "./ByrdhouseTab.css";
import PreCallConfig from "./PreCallConfig";
import * as teams from "@microsoft/teams-js";
import { useGetTeamsMeeting, useLogin } from "../hooks/api";
import { LiveKitConnection } from "./LiveKitConnection";
import { useMeetingId, useTeamsContext } from "../hooks/teams";
import useMeeting from "../hooks/meeting";
import { useToggleDialog } from "../hooks/layout";
import useUser from "../hooks/user";
import ContactSales from "./ContactSales";
import Loader from "./Loader";
import PersonalTab from "./PersonalTab";

export default function ByrdhouseTab() {
  useLogin();
  const [user] = useUser();

  const getTeamsMeeting = useGetTeamsMeeting();
  const [meeting, setMeeting] = useMeeting();

  const teamsContext = useTeamsContext();
  const meetingId = useMeetingId();

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getMeeting = async () => {
      if (!teamsContext) {
        return;
      }
      if (!meetingId) {
        return;
      }
      try {
        setLoading(true);
        const response = await getTeamsMeeting({ id: meetingId });
        setMeeting(response.data.meeting);
      } finally {
        setLoading(false);
      }
    };
    getMeeting();
  }, [teamsContext, getTeamsMeeting, setMeeting, meetingId]);

  // Show mic reminder dialog on open
  const toggleMicReminderDialog = useToggleDialog("mic-reminder");
  useEffect(() => {
    if (
      teamsContext &&
      teamsContext.page.frameContext === teams.FrameContexts.sidePanel
    ) {
      if (!meetingId) {
        return;
      }
      const alreadyShown = localStorage.getItem(meetingId);
      if (alreadyShown) {
        return;
      }
      toggleMicReminderDialog(true);
      localStorage.setItem(meetingId, JSON.stringify({ alreadyShown: true }));
    }
  }, [teamsContext, toggleMicReminderDialog, meetingId]);

  if (!teamsContext || loading) {
    return <Loader />;
  }

  if (!meetingId) {
    return <PersonalTab />;
  }

  if (!meeting) {
    return <Loader />;
  }

  const inMeeting =
    teamsContext.page.frameContext === teams.FrameContexts.sidePanel;

  if (
    (!user.auth && !inMeeting) ||
    (!user.auth?.pro && !inMeeting) ||
    !meeting.enabled
  ) {
    return <ContactSales />;
  }

  // In meeting, show translation ui
  if (inMeeting) {
    return (
      <div className="Translator">
        <LiveKitConnection />
      </div>
    );
  }

  // Not in meeting, show config if owner or show generic screen if not
  return <PreCallConfig existingMeetingId={meeting.id} />;
}
