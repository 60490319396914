import "./LogoHeader.css";
import { Image } from "@fluentui/react-components";

export default function LogoHeader() {
  return (
    <div className="LogoHeader">
      <Image src={"logo.png"} /> <h1>Byrdhouse AI</h1>
    </div>
  );
}
