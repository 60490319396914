import { createRoot } from "react-dom/client";
import App from "./components/App";
import "./index.css";
import config from "./components/lib/config";
import * as Sentry from "@sentry/react";

if (config.env === "production") {
  Sentry.init({
    dsn: "https://8330314e3224291d7b808ee643710176@o4503929032998912.ingest.us.sentry.io/4507942488440832",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
  });
}

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<App />);
