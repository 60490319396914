import "./Settings.css";
import { useCallback, useEffect, useMemo, useState } from "react";
import useUser from "../hooks/user";
import { AudioSettings, LocalStorageKeys, UserState } from "../types";
import TextCustomization from "./settings/TextCustomization";
import TextSpeed from "./settings/TextSpeed";
import { useStoreSettings } from "../hooks/layout";

const settingCategories = [
  "audio",
  "voice-avatar",
  "text-customization",
  "text-speed",
] as const;

type SettingCategory = (typeof settingCategories)[number];

function Settings() {
  // Save settings to local storage
  useStoreSettings();

  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [activeDeviceId, setActiveDeviceId] = useState<string>();

  // Load settings from storage
  useEffect(() => {
    const audioSettings = JSON.parse(
      localStorage.getItem(LocalStorageKeys.AUDIO_SETTINGS) || ""
    ) as AudioSettings;

    if (audioSettings.devices) {
      setDevices(audioSettings.devices);
    }

    if (audioSettings.activeDeviceId) {
      setActiveDeviceId(audioSettings.activeDeviceId);
    }
  }, []);

  const setActiveMediaDevice = useCallback(
    (deviceId: string) => {
      localStorage.setItem(
        LocalStorageKeys.AUDIO_SETTINGS,
        JSON.stringify({
          devices: devices,
          activeDeviceId: deviceId,
        })
      );
      setActiveDeviceId(deviceId);
    },
    [devices]
  );

  const [user, setUser] = useUser();

  const getCategoryName = useCallback((category: SettingCategory) => {
    switch (category) {
      case "audio":
        return "Audio";
      case "voice-avatar":
        return "Voice Avatar";
      case "text-customization":
        return "Text Customization";
      case "text-speed":
        return "Text Speed";
    }
  }, []);

  const [selectedSetting, setSelectedSetting] =
    useState<SettingCategory>("audio");

  const renderSettings = useMemo(() => {
    switch (selectedSetting) {
      case "audio":
        return (
          <div className="SettingDetails">
            <select
              onChange={(e) => setActiveMediaDevice(e.target.value)}
              value={activeDeviceId}
            >
              {devices.map((device) => (
                <option key={device.deviceId} value={device.deviceId}>
                  {device.label}
                </option>
              ))}
            </select>
          </div>
        );
      case "voice-avatar":
        return (
          <div className="SettingDetails">
            <select
              onChange={(e) =>
                setUser((prevState) => ({
                  ...prevState,
                  metadata: {
                    ...prevState.metadata,
                    aiVoiceGender: e.target
                      .value as UserState["metadata"]["aiVoiceGender"],
                  },
                }))
              }
              value={user.metadata.aiVoiceGender}
            >
              {[
                { label: "Male", value: "male" },
                { label: "Female", value: "female" },
              ].map((opt) => (
                <option key={opt.value} value={opt.value}>
                  {opt.label}
                </option>
              ))}
            </select>
          </div>
        );
      case "text-customization":
        return (
          <div className="SettingDetails">
            <TextCustomization />
          </div>
        );
      case "text-speed":
        return (
          <div className="SettingDetails">
            <TextSpeed />
          </div>
        );
    }
  }, [
    selectedSetting,
    setUser,
    user.metadata.aiVoiceGender,
    activeDeviceId,
    devices,
    setActiveMediaDevice,
  ]);

  return (
    <div className="Settings">
      <h1>Settings</h1>
      <div className="RenderedSetting">
        <div className="SettingsCategories">
          {settingCategories.map((category) => (
            <div
              key={category}
              style={
                selectedSetting === category ? { color: "white" } : undefined
              }
              onClick={() => setSelectedSetting(category)}
            >
              {getCategoryName(category)}
            </div>
          ))}
        </div>
        {renderSettings}
      </div>
    </div>
  );
}

export default Settings;
