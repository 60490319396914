import { FaMinus, FaPlus } from "react-icons/fa6";
import "./TextCustomization.css";
import { useCallback } from "react";
import useLayout from "../../hooks/layout";
// import { LayoutState } from "../../types";

export default function TextCustomization() {
  // const colorOptions = useMemo(() => {
  //   const options: LayoutState["settings"]["textColor"][] = [
  //     {
  //       text: "#101010",
  //       background: "#ccc",
  //     },
  //     {
  //       text: "#fff",
  //       background: "#101010",
  //     },
  //     {
  //       text: "#101010",
  //       background: "#fff",
  //     },
  //     {
  //       text: "#fff",
  //       background: "#00814A",
  //     },
  //     {
  //       text: "#fff",
  //       background: "#e76399",
  //     },
  //   ];
  //   return options;
  // }, []);

  const [layout, setLayout] = useLayout();

  const minTextSize = 12;
  const maxTextSize = 38;

  const setTextSize = useCallback(
    (textSize: number) => {
      setLayout((prevState) => ({
        ...prevState,
        settings: {
          ...prevState.settings,
          textSize: textSize,
        },
      }));
    },
    [setLayout]
  );

  const clamp = useCallback((value: number) => {
    return Math.min(Math.max(value, minTextSize), maxTextSize);
  }, []);

  const incrTextSize = useCallback(
    (incrBy: number) => {
      setLayout((prevState) => ({
        ...prevState,
        settings: {
          ...prevState.settings,
          textSize: clamp(prevState.settings.textSize + incrBy),
        },
      }));
    },
    [setLayout, clamp]
  );

  return (
    <div className="TextCustomization">
      <div>
        Text Size
        <div className="TextSizeSlider">
          <FaMinus onClick={() => incrTextSize(-1)} />
          <input
            type="range"
            min={minTextSize}
            max={maxTextSize}
            value={layout.settings.textSize}
            onChange={(e) => setTextSize(Number(e.target.value))}
          />
          <FaPlus onClick={() => incrTextSize(1)} />
        </div>
      </div>
      {/* <div>
        Text Color
        <div className="TextCaptionExamples">
          {colorOptions.map((option, index) => (
            <div
              key={index}
              style={{
                border:
                  layout.settings.textColor.text === option.text &&
                  layout.settings.textColor.background === option.background
                    ? "2px solid #2672ED"
                    : "2px solid transparent",
                color: option.text,
                backgroundColor: option.background,
              }}
              onClick={() =>
                setLayout((prevState) => ({
                  ...prevState,
                  settings: {
                    ...prevState.settings,
                    textColor: {
                      text: option.text,
                      background: option.background,
                    },
                  },
                }))
              }
            >
              Text
            </div>
          ))}
        </div>
      </div> */}
    </div>
  );
}
