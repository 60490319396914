import "./NoMinutesDialog.css";
import Dialog from "./Dialog";

function NoMinutesDialog() {
  return (
    <Dialog title={"Reminder"} small={true}>
      <div className="NoMinutesDialog">
        <div>
          You've used all your translation minutes this month. Please contact
          <a href="mailto:jacob.greenway@byrdhouseapp.com?subject=Byrdhouse%20Minutes">
            jacob.greenway@byrdhouseapp.com
          </a>{" "}
          to purchase more.
        </div>
      </div>
    </Dialog>
  );
}

export default NoMinutesDialog;
