import React, { useCallback, useEffect, useRef } from "react";
import "./Dialog.css";
import { IoClose } from "react-icons/io5";

type DialogProps = {
  children: React.ReactNode;
  className?: string;
  title: string;
  onClose?: () => void;
  small?: boolean;
};

function Dialog(props: DialogProps) {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        props.onClose !== undefined &&
        contentRef.current &&
        (!event.target ||
          (event.target instanceof Element &&
            !contentRef.current.contains(event.target)))
      ) {
        props.onClose();
      }
    },
    [props]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <dialog
      open={true}
      className={
        (props.className ? props.className : "") +
        (props.small ? " SmallDialog" : "")
      }
    >
      <div
        className={props.onClose !== undefined ? "ClosableDialog" : undefined}
        ref={contentRef}
      >
        <div className="Title">
          {props.title}
          {props.onClose !== undefined && (
            <IoClose className="CloseDialog" onClick={props.onClose} />
          )}
        </div>
        {props.small ? <div>{props.children}</div> : props.children}
      </div>
    </dialog>
  );
}

export default Dialog;
