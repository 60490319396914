import "./Permissions.css";
import { Image } from "@fluentui/react-components";

export default function Permissions() {
  return (
    <div className="Permissions">
      <div>Please grant microphone access to use Byrdhouse AI translation:</div>
      <div>1. Click the "Settings" button in the top-right corner.</div>
      <Image src={"permissions-1.png"} />
      <div>2. Go to "App Permissions".</div>
      <Image src={"permissions-2.png"} />
      <div>3. Enable media permissions to enable microphone access.</div>
    </div>
  );
}
